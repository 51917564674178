<template>
  <div>
    <h3>What are mirrors?</h3>
    <p>
      A mirror is a smooth and highly polished surface that reflects light and leads to the formation of an image. When
      an object is kept in front of the mirror, it acts as the source of the incident ray, and its image is formed in
      the mirror at the point where the reflected rays meet.
    </p>
    <h3>Spherical mirrors</h3>
    <p>
      Mirrors that have curved reflecting surfaces are called spherical mirrors. A spherical mirror is a part of a
      hollow sphere that has been silvered either on the outer or inner surface of the sphere. There are two types of
      spherical mirrors depending on whether the inner or outer surface of the sphere is silvered, convex mirrors and
      concave mirrors.
    </p>
    <ul class="a">
      <li>
        <h5>Concave Mirror</h5>
        A spherical mirror made by silvering its outer surface such that its inner surface acts as the reflecting
        surface is known as a concave mirror. A concave mirror is also known as a converging mirror as it
        converges
        the light rays incident on it after reflection.
      </li>
      <li>
        <h5> Convex Mirror</h5>
        A spherical mirror made by silvering its inner surface such that its outer surface acts as the
        reflecting surface is known as a convex mirror. Its reflecting surface bulges out. A convex mirror is
        also known as a diverging mirror as it diverges the light rays incident on it after reflection.
      </li>
      <figure align="middle">
        <img src="/assets/mirror_image_1.jpg" border="2"></img>
        <figcaption><h5>Fig: Concave and Convex Mirrors</h5></figcaption>
      </figure>
    </ul>

    <h3>Features of Spherical Mirrors</h3>
    <p>
      Now we will have a look at some important terms related to spherical mirrors that will help us to draw
      ray diagrams further in this module.
    </p>
    <ul>
      <li>
        <b>Centre of curvature (C):</b> It is the centre of the sphere of which the spherical mirror is a part.
      </li>
      <li><b>Pole (P):</b>The centre of the mirror surface is called the pole.</li>
      <li>
        <b>Principal Axis:</b>It is an imaginary line perpendicular to the tangent drawn at the pole of
        the mirror
        and passing through the pole and centre of curvature of the mirror. In the figure shown above line PC represents the principal axis.
      </li>
      <li>
        <b>Aperture (AB):</b>It is the part of the mirror that is exposed to the incident light. It tells us
        about the size of the mirror. AB is the aperture of mirror in the figure above.
      </li>
      <li><b>Radius of Curvature (R):</b>It is the radius of the sphere the spherical mirror is a part of.</li>
      <li>
        <b>Focus (F):</b>It is a point on the principal axis from where the incident ray parallel to principal
        axis meet or appear to meet after reflection from the mirror. A concave mirror is said to have a
        real focus since the light rays incident parallel to principal axis after reflection actually meets
        at this point. On the other hand, a convex mirror is said to have a virtual focus since the
        light rays after reflection do not actually meet, but they appear to meet at this point.
      </li>
      <li>
        <b>Focal Length:</b>
        It is the distance between the pole and focal point of the mirror. It is equal to half the radius of
        curvature of the mirror.
      </li>
    </ul>

    <h3>Image Formation in Spherical Mirrors</h3>
    <h3>REAL AND VIRTUAL IMAGE</h3>
    <p>
      When the image of an object is formed by actual intersection of the reflected rays, the image is said to be
      real. It is inverted. On the other hand, when the reflected rays do not actually meet, but they appear to meet
      when extended, the image formed in this case is called a virtual image. A virtual image is upright.
    </p>

    <h3>CONVENIENT RAYS FOR THE CONSTRUCTION OF AN IMAGE BY A RAY DIAGRAM:</h3>
    <p>
      Although there are infinite number of rays coming out from a point of an object but the path of all of these
      rays after reflection are not known. Therefore, we look at four convenient rays among them and study about the
      path they follow after reflection from the mirror. Any two of the rays listed below can be used to draw the ray
      diagrams:
    </p>
    <ol>
      <li> A ray passing through center of curvature retraces its path after reflection.</li>
      <li>
        A ray passing through focus or appearing to pass through focus becomes parallel to the principal axis
        after reflection from the mirror.
      </li>
      <li>
        A ray incident parallel to the principal axis after reflection from the mirror passes or appears to
        pass through the focus of the mirror.
      </li>
      <li>
        A ray incident at the pole after reflection makes equal angle with the principal axis as the incident
        ray made with the principal axis before reflection.
      </li>
    </ol>
    <h3>Ray Diagrams for formation of image </h3>

    <p>
      Now we shall study the position and nature of image formed when a small linear object is kept at different
      positions in front of the mirror by drawing ray diagrams. Based on the object distance, image formation is
      divided into a few cases for convenience. However, you can see the ray diagram and nature of image formed
      keeping the object at any distance as you like with the help of magic graph at the end of this module.
    </p>
    <h3>Image formation in concave mirror</h3>
    <p>
      Below is a table that shows the ray diagram, position, size and nature of the image formed when object is kept
      at different positions in front of a concave mirror.
    </p>
    <table border="5" bordercolor="black" align="center">
      <tr>
        <th>No.</th>
        <th>Position of object</th>
        <th>Position of image</th>
        <th>Size of Image</th>
        <th>Nature of Image</th>
        <th>Ray Diagram</th>
      </tr>
      <tr>
        <td>1.</td>
        <td>At infinity	</td>
        <td>At Focus</td>
        <td>Highly diminished to a point</td>
        <td>Real and inverted</td>
        <td><img src="/assets/diagram_1.jpg" border="2"></img></td>
      </tr>
      <tr>
        <td>2.</td>
        <td>Beyond the centre of curvature</td>
        <td>Between the centre of curvature and focus</td>
        <td>Diminished</td>
        <td>Real and Inverted</td>
        <td> <img src="/assets/diagram_2.jpg" border="2"></img></td>
      </tr>
      <tr>
        <td>3.</td>
        <td>At the centre of curvature</td>
        <td>At centre of curvature</td>
        <td>Same Size</td>
        <td>Real and inverted</td>
        <td><img src="/assets/diagram_3.jpg" border="2"></img></td>
      </tr>
      <tr>
        <td>4.</td>
        <td>Between the centre of curvature and focus</td>
        <td> Beyond the centre of curvature</td>
        <td>Enlarged</td>
        <td>Real and inverted</td>
        <td><img src="/assets/diagram_4.jpg" border="2"></img></td>
      </tr>
      <tr>
        <td>5.</td>
        <td> At focus </td>
        <td>At infinity</td>
        <td>Highly enlarged</td>
        <td>Real and inverted</td>
        <td><img src="/assets/diagram_5.jpg" border="2"></img></td>
      </tr>
      <tr>
        <td>6.</td>
        <td width="200">
          Between the focus and pole
        </td>
        <td width="250">
          Behind the mirror
        </td>
        <td width="250">
          Enlarged
        </td>
        <td width="200">
          Virtual and upright
        </td>
        <td><img src="/assets/diagram_6.jpg" border="2"></img></td>
      </tr>
    </table>
    <h3><u>Image formation in convex mirror</u></h3>
    <p>There are two cases in convex mirror which are listed in the table below. </p>
    <table border="5" bordercolor="black" align="center">
      <tr>
        <th>No.</th>
        <th>Position of object</th>
        <th>Position of image</th>
        <th>Size of Image</th>
        <th>Nature of Image</th>
        <th>Ray Diagram</th>
      </tr>
      <tr>
        <td>1.</td>
        <td>At infinity	</td>
        <td>At Focus, behind the mirror</td>
        <td>Highly diminished to a point</td>
        <td>Virtual and upright</td>
        <td><img src="/assets/diagram_8.jpg" border="2"></img></td>
      </tr>
      <tr>
        <td>2.</td>
        <td width="200">
          At any other position
        </td>
        <td width="250">
          Between pole and focus, behind the mirror
        </td>
        <td width="250">
          Diminished
        </td>
        <td width="200">
          Virtual and upright
        </td>
        <td><img src="/assets/diagram_7.jpg" border="2"></img></td>
      </tr>
    </table>

    <h3>Mirror Formula and Sign convention</h3>
    <p>
      Now we will study the mirror formula using which we can find the image of an object no matter what is the position
      of the object but before we proceed on to study mirror formula let us study about sign conventions using which the
      mirror formula is derived and which has to be kept in mind while solving any problem in optics.
    </p><h5>Sign Convention</h5>
    <ol>
      <li> All distances are measured from the pole of the spherical mirror.</li>
      <li>
        The distances measured in the direction of the incident ray are considered to be positive and the
        distances in the direction opposite to incident ray are considered to be negative.
      </li>
      <li>	All measurements of heights above the principal axis are taken to be positive and below are taken to be negative.</li>
    </ol>
    </p>
    <h5>Mirror Formula</h5>
    <p>
      The mirror formula is given as:$$\frac{1}{f}=\frac{1}{u}+\frac{1}{v} $$
      <br><br>Where <em>u</em> is the object distance, <em>v</em> is the image distance and <em>f</em> is the focal length of the mirror.
      <br><br>It was derived keeping the sign convention in mind,
      therefore whenever using this formula the values of u, v and f must be substituted with proper signs according to the sign convention.
    </p>
    <h5>Magnification</h5>
    <p>
      It is the ratio of size of the image to the size of the object. It tells us whether the image formed is enlarged or diminished.
      $$m=\frac{\text{image height}}{\text{object height}}=\frac{h_i}{h_o}=\frac{-v}{u} \text{   (on applying sign convention)}$$
      where  <em>v</em> and <em>u</em> are image and object distance respectively.
      Magnification is negative for inverted image and positive for upright image.
    </p>
    <h5>Power of a mirror</h5>
    <p>
      Power P of a mirror is given as:$$P=\frac{1}{f}$$
      where <em>f</em> is the focal length in meter and is
      substituted with proper sign according to the sign convention. Its S.I unit is <b>Dioptre</b>. </pi>
    </p><h3 ref="playgraph">
      MagicGraph | Image Formation in a Concave Mirror
    </h3>
    <p>This MagicGraph offers a visually interactive assistant that helps student understand the process of image formation in a concave mirror.</p>
    <h5> To Get Started </h5>
    <p>
      The MagicGraph shows a concave mirror with an object (candle) placed in front of it. You can change the position of the object
      and interactively visualize the position, size and nature of the image formed.
    </p>
    <ul style="list-style-type: square;">
      <li>Move and position the object (AB) in front of the mirror. Note that the position of the object is measured from the pole (P) of the mirror. </li>
      <li>MagicGraph creates the image of the object (A'B') based on mirror formula. </li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve.</h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'SphericalMirrors',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Concave Mirrors';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Resistor', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Resistors Connected in Parallel', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'Resistors Connected in Series', img:'/assets/number-3.svg', action: () => this.goto('iat')},
      {title: 'Graphical Interpretation',img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
